<script setup lang="ts">
import { onMounted, ref, reactive } from 'vue';

import { deferLoading, loadingDone } from '@mfl/framework';
import { WsInput, WsButton } from '@mfl/common-components';
import { surveyResponseGateway } from '@msl/survey-response-gateway-sdk';

import strings from './survey-response.strings';

enum LayoutStates {
  score = 0,
  text = 1,
  final = 2,
  answered = 3,
}

enum SurveyType {
  nps = 0,
  enps = 1,
  csat = 2,
  ces = 3,
}

interface ResponseData {
  score?: number;
  text?: string;
  surveyType?: SurveyType;
  responseId: string;
  deactivated?: boolean;
}

const surveyId = window.location.pathname.split('/').pop();
const layout = ref<LayoutStates>(
  localStorage.getItem(`score${surveyId}`) ? 1 : 0
);
const logo = ref<string | undefined>('');
const isLoading = ref<boolean>(true);
const surveyTypeString = ref<string>('');

const responseData = reactive<ResponseData>({
  responseId: localStorage.getItem(`responseId${surveyId}`) || '',
});

const handleRate = async (star: number) => {
  responseData.score = star;
  await submit();
};

const submit = async () => {
  try {
    const { score, text, responseId } = responseData;
    await surveyResponseGateway.update({
      id: responseId,
      score: score,
      text: text,
    });
    layout.value = text ? 2 : 1;
    text && localStorage.setItem(`text${surveyId}`, text);
    score !== undefined &&
      localStorage.setItem(`score${surveyId}`, score.toString());
  } catch (_err) {
    console.error('Error submitting response. Try again later.');
  }
};

const fetchSurveyType = async () => {
  try {
    const res = await surveyResponseGateway.get({ id: surveyId });
    if (res.statusCode !== 0 || res.type === undefined) {
      console.error('Survey is not defined.');
      responseData.surveyType = undefined;
      return;
    }

    logo.value = res.logo;
    responseData.surveyType = res.type;
    surveyTypeString.value = SurveyType[responseData?.surveyType] as string;
    responseData.deactivated = !!res.deactivated;
  } catch (_err) {
    console.error('Error fetching survey type. Try again later.');
    responseData.surveyType = undefined;
  } finally {
    isLoading.value = false;
  }
};

const createResponse = async () => {
  try {
    if (
      responseData.deactivated ||
      responseData.surveyType === undefined ||
      localStorage.getItem(`responseId${surveyId}`)
    ) {
      return;
    }

    const response = await surveyResponseGateway.create({
      surveyId,
      surveyType: responseData.surveyType,
    });
    responseData.responseId = response.id || '';
    localStorage.setItem(`responseId${surveyId}`, responseData.responseId);
  } catch (_err) {
    console.error(_err);
  }
};

onMounted(async () => {
  try {
    deferLoading();
    await surveyResponseGateway.init();
    await fetchSurveyType();
    const responseId = localStorage.getItem(`responseId${surveyId}`);
    responseData.text = localStorage.getItem(`text${surveyId}`) || '';
    const storedScore = localStorage.getItem(`score${surveyId}`);
    responseData.score = storedScore === null ? undefined : +storedScore;
    if (!responseId) {
      await createResponse();
    } else if (responseData.text && responseData.score !== undefined) {
      layout.value = 3;
    }
  } finally {
    loadingDone();
  }
});
</script>

<template>
  <div v-if="isLoading" class="loader-container">
    <span class="fa-regular fa-spinner-third" aria-hidden="true" />
  </div>
  <div
    v-if="responseData.surveyType === undefined && !isLoading"
    class="empty-state-container"
  >
    <img
      class="empty-state-logo"
      alt="survey response"
      src="./assets/ws-logo.svg"
    />
    <span class="empty-state-header">{{ strings.emptyStateHeader }}</span>
  </div>
  <div
    v-else-if="responseData.surveyType !== undefined && !isLoading"
    class="survey-container"
  >
    <img :src="logo" alt="logo" class="logo" />
    <h4 class="header">
      {{
        layout === 2 || layout === 3
          ? strings[`${LayoutStates[layout]}Header` as keyof object]
          : strings[
              `${LayoutStates[layout]}Header${surveyTypeString.toUpperCase()}` as keyof object
            ]
      }}
    </h4>
    <div v-if="layout === 0" class="flex-col">
      <div v-if="surveyTypeString === 'csat'">
        <div class="flex stars">
          <div
            v-for="star in 5"
            :key="star"
            class="star-wrapper"
            @click="handleRate(star)"
          >
            <div
              :class="[
                'star',
                responseData.score && star <= responseData.score
                  ? 'active-star'
                  : '',
              ]"
            ></div>
            <span>{{ star }}</span>
          </div>
        </div>
        <div class="scat scale">
          <div>Very unsatisfied</div>
          <div>Very satisfied</div>
        </div>
      </div>
      <div v-if="surveyTypeString === 'nps'">
        <div class="flex">
          <div
            v-for="square in 11"
            :key="square - 1"
            :class="[
              'square',
              square - 1 === responseData.score ? 'active' : '',
            ]"
            @click="handleRate(square - 1)"
          >
            {{ square - 1 }}
          </div>
        </div>
        <div class="scale">
          <div>Least likely</div>
          <div>Neutral</div>
          <div>Most likely</div>
        </div>
      </div>
    </div>
    <div v-else-if="layout === 1" class="flex-col">
      <WsInput
        v-model="responseData.text"
        aid="SURVEY_RESPONSE_TEXT_INPUT"
        size="lg"
        :placeholder="strings.textPlaceholder"
      />
      <WsButton
        aid="SURVEY_RESPONSE_SUBMIT_BUTTON"
        label="Submit"
        color="primary"
        size="lg"
        :disabled="!responseData.text"
        @click="submit()"
      ></WsButton>
    </div>
    <footer>
      Powered by
      <img src="./assets/ws-logo.svg" alt="logo" />
    </footer>
  </div>
</template>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-state-logo {
    display: block;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    width: 370px;
    height: 244px;
  }

  .empty-state-header {
    font-size: 20px;
    font-weight: 700;
  }
}

.scale {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #828282;
  font-size: 13px;
  padding: 40px 0;
}

.scat {
  padding: 8px 20px;
}

.survey-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  text-align: center;

  .logo {
    width: 120px;
    height: 120px;
    border-radius: 100px;
    background-color: white;
    color: white;
  }

  .header {
    font-size: 26px;
    font-weight: 600;
  }

  .stars {
    margin-left: 44px;
  }

  .square {
    margin: 0 5px 0 0;
    text-align: center;
    cursor: pointer;
    border-radius: 0.33px;
    width: 60px;
    height: 60px;
    background: #f4f6f8;
    transition-duration: 0.3s;
    color: #2d2e30;
    font-size: 28px;
    border: 0.83px solid #edeeef;
    align-content: center;
    font-weight: 700;

    &&:hover {
      background: #e7e7e7;
    }
  }

  .active {
    background: #aaafb4;

    &&:hover {
      background: #aaafb4;
    }
  }

  .star-wrapper {
    padding: 0 40px 0 0;
    text-align: center;
    cursor: pointer;

    .star {
      width: 60px;
      height: 60px;
      background: #e0e0e0;
      mask-image: url(./assets/star.svg);
      mask-repeat: no-repeat;
      mask-position: top right;
      transition-duration: 0.3s;
    }

    span {
      color: #828282;
      font-weight: 400;
      font-size: 28px;
      transition-duration: 0.3s;
    }

    &&:has(~ .star-wrapper:hover) .star,
    &&:hover .star,
    + .star,
    .active-star {
      background: #ffd527;

      span,
      + span {
        color: black;
      }
    }
  }

  button {
    padding: 12px 24px;
  }

  label {
    width: 560px;
  }

  footer {
    text-align: center;
    position: absolute;
    bottom: 40px;
  }

  @media screen and (max-width: 420px) {
    .header {
      font-size: 23px;
    }

    .stars {
      margin-left: 0px;

      span {
        font-size: 22px;
      }
    }
    .star-wrapper {
      padding: 0 10px 0 0;

      .star {
        width: 50px;
        height: 50px;
        mask-image: url(./assets/star-s.svg);
      }
    }
    .square {
      width: 32px;
      height: 32px;
      margin: 0;
      font-size: 20px;
    }
  }

  @media screen and (min-width: 421px) and (max-width: 500px) {
    .stars {
      margin-left: 20px;
    }
    .star-wrapper {
      padding: 0 20px 0 0;

      .star {
        width: 55px;
        height: 55px;
        mask-image: url(./assets/star.svg);
      }
    }

    .square {
      width: 37px;
      height: 37px;
      margin: 0;
      font-size: 23px;
    }
  }

  @media screen and (min-width: 501px) and (max-width: 800px) {
    .stars {
      margin-left: 30px;
    }
    .star-wrapper {
      padding: 0 30px 0 0;

      .star {
        width: 55px;
        height: 55px;
        mask-image: url(./assets/star.svg);
      }
    }

    .square {
      width: 43px;
      height: 43px;
      font-size: 26px;
      margin: 0 2px 0 0;
    }
  }

  @media screen and (max-width: 560px) {
    label {
      width: 90vw;
    }
  }
}
</style>
