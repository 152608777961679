export default {
  header: 'Welcome to survey-response',
  emptyStateHeader: `There's no such survey`,
  answeredHeader: 'Survey was already answered',
  buttonLabel: 'CC Button',
  scoreHeaderCSAT: 'How satisfied are you with our service?',
  textHeaderCSAT: 'What could we do to improve your experience?',
  scoreHeaderNPS:
    'How likely are you to recommend WiseStamp to a friend or colleague?',
  textHeaderNPS: 'What is the primary reason for your score?',
  finalHeader: 'Thank you for your time!',
  textPlaceholder: 'Type here',
};
